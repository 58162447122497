import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import { ArrowForward } from "@material-ui/icons";

function FundingButton() {
  return (
    <div>
      <button className="StartButton">
        <div>Join the CleeQ</div>

        <ArrowForward />
      </button>
    </div>
  );
}

export default FundingButton;
