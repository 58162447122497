import {
  AccountTree,
  AssignmentReturnedOutlined,
  EmojiEventsOutlined,
  MeetingRoom,
} from "@material-ui/icons";
import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function XServices() {
  return (
    <div className="container JoinCont3">
      <div className="PacCont">
        <div className="container p-0 PacLanding overflow-hidden ">
          <div className="TaskWrapper">
            <center className="WhyChooseWrapper">
              <center className="WhyChooseContainer">
              Some of our special features include






              </center>{" "}
            </center>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row">
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <MeetingRoom className="AccIcon" />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Tithes</div>
                            <div className="ACCSub">
                              We've enhanced the convenience of tithing by
                              allowing you to pay your tithe to multiple
                              churches in one go.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <AccountTree className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Offering</div>
                            <div className="ACCSub">
                              Embrace digital giving by conveniently offering
                              your contributions anywhere and anytime.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <EmojiEventsOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Donations/Seeds</div>
                            <div className="ACCSub">
                              Easily make donations seamlessly to any religious body
                              .
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row">
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Events</div>
                            <div className="ACCSub">
                            Create a program or event, choose between free and paid ticket options, and share the link
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default XServices;
