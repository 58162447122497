
import React, { useEffect } from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import Aos from "aos";
import "aos/dist/aos.css";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import AboutUs from "./AboutUs";
import Problems from "./Problems";
import OurVision from "./OurVision";
import OurCoreValues from "./OurCoreValues";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function WhoWeAre() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.scrollTo(0, 0); // This will scroll the page to the top when the component mounts
  }, []);

  const classes = useStyles();

  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>

        <section>
          <div>
            <AboutUs />
          </div>{" "}
          <br />
          <br />
          <br />
          <br /> <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <Problems />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <OurVision />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <OurCoreValues />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default WhoWeAre;
