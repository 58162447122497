// import React from "react";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import CleeQFunds from "./CleeQFunds";
import Charity from "./Charity";
import MedicalSupport from "./MedicalSupport";
import Schoolsupport from "./Schoolsupport";
import Ngo from "./Ngo";
import PersonalSupport from "./PersonalSupport";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import JoinOver from "../../Components/JoinOver";
import FundingSearch from "./FundingSearch";
import FundingServices from "./FundingServices";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));


  function CleeQFunding() {
    useEffect(() => {
      Aos.init({ duration: 1000 });
      window.scrollTo(0, 0);  // This will scroll the page to the top when the component mounts
    }, []);
  
  const classes = useStyles();
  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>
        <section>
          <div>
            <CleeQFunds />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <JoinOver />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <FundingSearch />
          </div>
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <div data-aos="fade-up">
            <Charity />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <MedicalSupport />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <PersonalSupport />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <Ngo />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <Schoolsupport />
          </div>
        
          <br /> <br /> <br /> <br />
          {/* <div data-aos="fade-up">
            <FundingServices />
          </div> */}
          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default CleeQFunding;
