import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import {
  AccountTree,
  AssignmentReturnedOutlined,
  BlurCircularOutlined,
  CardMembershipOutlined,
  ColorizeOutlined,
  EmojiEventsOutlined,
  FaceOutlined,
  MeetingRoom,
  SupervisorAccountOutlined,
} from "@material-ui/icons";

function ChatSearvices() {
  return (
    <div>
      <div className="container JoinCont3">
        <div className="PacCont">
          <div className="container p-0 PacLanding overflow-hidden ">
            <div className="TaskWrapper">
              <div className="container p-0  overflow-hidden">
                <div className="row">
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <AssignmentReturnedOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Events</div>
                              <div className="ACCSub">
                                Do we have an event? Let's talk about it here
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="">
                              <div className="IconCont1">
                                <AccountTree className="AccIcon" />

                                {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Meetings</div>
                              <div className="ACCSub">
                                Let's discuss our meetings in this chatroom.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <EmojiEventsOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Projects</div>
                              <div className="ACCSub">
                                Our project has started, let's discuss
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatSearvices;
