import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import FundingButton from "./FundingButton";
// import "../../Components/FundRaisingBtn";

function Charity() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageImg">
          <img src="/assets/Funding/CHA.png" alt="" className="PackImg01" />
        </div>
        <div className="PackageWriteUp">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Charity
              <span className="LandingSUB2">&nbsp;Fundraising&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQFunding is pivotal for raising awareness and
              effecting positive social change. Through skillful use of
              philanthropy and community backing, fundraisers can significantly
              impact pressing societal issues, enhancing the lives of those in
              need.
            </div>
          </div>

          <FundingButton />
        </div>
      </div>
    </div>
  );
}

export default Charity;
