import { ArrowForwardIosOutlined, AssignmentIndOutlined, Close, CreditCardOutlined, PersonOutlineOutlined, ViewAgendaOutlined } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import "../../Pages/CleeQ.css";
// import "../../Components//Modals/Modals.css";

function Request() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div>
        <div className="container">
          <div className="PackCont8088">
            <div className="container-fluid">
              <div className="row g-5">
                <div className="col-lg-7">
                  <div className="container-fluid p-0">
                    <div className="row g-5">
                      <div className="col-6">
                      <div className="border RequestCONT40">
                          <div className="Iconcover">
                            
                            <CreditCardOutlined className="AccIcon2" />
                          </div>
                          <div>
                          <div className="LetterHead2">ATM Card Request</div>
                          <div className="LetterSub2">We deliver in 48 hours</div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col-6">
                      <div className="border RequestCONT40">
                          <div className="Iconcover">
                            <AssignmentIndOutlined className="AccIcon2" />
                          </div>
                          <div>
                          <div className="LetterHead2">Account Statement</div>
                          <div className="LetterSub2">Instant account statement</div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="border RequestCONT40">
                          <div className="Iconcover">
                            
                            <ViewAgendaOutlined className="AccIcon2" />
                          </div>
                          <div>
                          <div className="LetterHead2">Refrence Letter</div>
                          <div className="LetterSub2">Request for Refrence Letter</div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col-6">
                      <div className="border RequestCONT40">
                          <div className="Iconcover">
                            
                            <PersonOutlineOutlined className="AccIcon2" />
                          </div>
                          <div>
                          <div className="LetterHead2">....More</div>
                          <div className="LetterSub2">Do more</div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="SupCont">
                    <div className="LandingHedings4000">
                      Lets Go Branchless and
                      <span className="LandingSUB2">
                        &nbsp;Paperless.&nbsp;
                      </span>
                    </div>
                    <br />
                    <br />
                    <div className="FundingSunHead">
                      Embrace convenience, save time, access your banking
                      services securely, and have the flexibility to manage your
                      finances remotely from anywhere.
                    </div>
                    <br />
                    <br />
                    <br />
                    <button className="StartBTN1">
                      Join The CleeQ For Free
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="CloseBtnCont">
              <Close className="CloseBtn22" onClick={handleClose} />
            </div>
            <div className="BodyCont">
              <div className="StrImgCont">
                <img src="/assets/38.png" alt="" className="LogoImg" />
              </div>{" "}
              <br /> <br />
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;Exclusive&nbsp;</span>-
                <span className="HowMuch">&nbsp;Free.&nbsp;</span>
              </div>
              <br />
              <p className="Tittle2">
                Managing members tithes, offerings and donations as a church
                online with CleeQ24 is a great benefit to both the church and
                its members. <br /> <br /> CleeQ24 provide members with a
                convenient way to give, as they can make donations, tithes and
                offering from anywhere around the world.
                <br /> <br />
                CleeQ24 helps the church to streamline its financial operations,
                reduce paperwork, and make it easier to track donations, tithes
                and offering from its members. <br /> <br />
                Additionally, CleeQ24 increase transparency and accountability,
                as members can easily view their giving history and track their
                donations. <br /> <br />
                So, why not create your church's CleeQ24 account, share the
                username to members and the public to start receiving donations
                even from non members of your church. <br />
                <br />
              </p>
              <br />
              <br />
              <p className="Tittle2">
                More reasons why you should create a CleeQ Exclusive Account for
                your group.
                <br />
                <br />
                <ul>
                  <li>
                    Convenience: CleeQ Exclusive provides a convenient and
                    hassle-free way for church members to contribute
                    financially. They can donate from the comfort of their homes
                    or on-the-go using various electronic devices, such as
                    smartphones, tablets, or computers. It eliminates the need
                    for writing checks or carrying cash to the church.
                  </li>
                  <br />
                  <li>
                    Accessibility and Reach: CleeQ Exclusive allows church
                    members to contribute regardless of their physical location.
                    It extends the reach of the church's donation opportunities
                    beyond the local congregation, enabling individuals from
                    different geographic areas to support the church's mission
                    and activities.
                  </li>
                  <br />
                  <li>
                    Regular Giving and Recurring Donations: CleeQ Exclusive
                    offers the option for recurring donations. Church members
                    can set up automatic, recurring contributions, ensuring a
                    consistent and reliable stream of financial support for the
                    church's ongoing operations, ministries, and charitable
                    initiatives.
                  </li>
                  <br />

                  <li>
                    Financial Transparency and Accountability: CleeQ Exclusive
                    provides detailed transaction records and receipts for
                    donors. This promotes financial transparency, allowing
                    church members to track and review their giving history and
                    ensure accuracy. It also simplifies the process of
                    generating tax receipts for charitable contributions.
                  </li>
                  <br />

                  <li>
                    Increased Giving Options: CleeQ Exclusive offers a variety
                    of giving options beyond traditional cash or check
                    donations. Church members may have the opportunity to
                    contribute to specific funds, campaigns, or designated
                    projects according to their interests and priorities. This
                    flexibility can help align donations with the individual's
                    preferences and enhance their sense of engagement and
                    impact.
                  </li>
                  <br />

                  <li>
                    Enhanced Security: CleeQ Exclusive prioritizes the security
                    and protection of sensitive financial information.
                    Encryption and other security measures are in place to
                    safeguard donor data, providing peace of mind to those
                    making online contributions.
                  </li>
                  <br />

                  <li>
                    Analytics and Reporting: CleeQ Exclusive provides analytics
                    and reporting features. This enables church leaders and
                    administrators to track donation trends, monitor fundraising
                    progress, and gain insights into the giving patterns of the
                    congregation. These analytics can inform strategic
                    decision-making and enhance stewardship efforts.
                  </li>
                  <br />

                  <li>
                    Cost-Effective and Efficient: CleeQ Exclusive is
                    cost-effective for both the church and its members. It
                    reduces administrative tasks associated with handling
                    physical checks and cash, such as counting, recording, and
                    depositing. Additionally, it minimizes the need for manual
                    data entry, freeing up resources and time for other
                    important church activities.
                  </li>
                  <br />

                  <li>
                    CleeQ Exclusive offers convenience, accessibility,
                    transparency, and increased giving options for church
                    members. It promotes financial stewardship, streamlines
                    administrative processes, and strengthens the financial
                    stability of the church, allowing it to fulfill its mission
                    and serve the community effectively.
                  </li>
                </ul>
              </p>
              <br />
              <br />
              <p className="Tittle2">
                <div className="MoreHeadings">CleeQ Exclusive Content</div>
                <p className="Tittle2">
                  We have set up what is required in the Exclusive package for
                  your use. below are the list of things you will
                </p>
                <br />
                <br />
                <ul>
                  <div className="MoreHeadings">CleeQ Exclusive Account</div>
                  <li>Unique Basic Username</li>
                  <li>One Basic Account Number</li>
                  <li>Unique Basic ID</li>
                  <li>Sharable Donation Link</li>
                  <br />
                  <br />
                  <div className="MoreHeadings">Exclusive Activities</div>
                  <li>Basic Wallet</li>
                  <li>Basic Donation Statistics</li>
                  <li>Basic Tithes Statistics</li>
                  <li>Basic Offerings Statistics</li>
                  <li>Personal Offerings Statistics</li>
                  <li>Personal Donation Statistics</li>
                  <li>Personal Tithes Statistics</li>

                  <li>Send Money to any CleeQ Funder or any bank account</li>
                  <li>
                    Receie Money with Account Number, Username, unique Family ID
                    and Phone number from any bank
                  </li>
                  <li>
                    Project : On Going, Suspended, Cancelled and completed
                    project records with the option of adding committees for
                    each project.
                  </li>
                  <li>Events : Past and Upcoming events records</li>

                  <br />
                  <br />
                  <div className="MoreHeadings">
                    CleeQ Exclusive Mandate and Funds Security
                  </div>
                  <li>Multiple Admin Users</li>
                  <li>More than one Signatory for funds withdrawal</li>
                </ul>
                <br />
              </p>
              <button className="StartBTN1">Start a CleeQ Exclusive Now</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Request;