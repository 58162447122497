import React from 'react'
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function CleeQInvestBtn() {
  return (
        <div>
        <button className="StartBTN1">Start Investing Now</button>
      </div>
  )
}

export default CleeQInvestBtn