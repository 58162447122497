import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundingButton from "../CleeQFunding/FundingButton";

function Pro() {
  return (
    <div className="container ">
      <div className="PackageWrapper02">
        <div className="PackageWriteUp02">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              CleeQ
              <span className="LandingSUB2">&nbsp;Pro&nbsp;</span>
            </div>
            <div className="SubHead">
              Tailored for professional organizations, CleeQ Pro streamlines
              finances, ensuring optimal resource utilization. With CleeQ,
              professionals bodies gain enhanced security and transparency for
              financial transactions, alongside organized financial management
              and meeting procedures.
              <br />
              <br />
              {/* <span className="ReadMore" onClick={handleShow}>
        Know More about CleeQFamily...
      </span> */}
            </div>
          </div>

          <FundingButton />

        </div>
        <div className="PackageImg02">
          <img src="/assets/PR.png" alt="" className="PackImg02" />
        </div>
      </div>
    </div>
  );
}

export default Pro;
