import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundingButton from "../CleeQFunding/FundingButton";

function Companion() {
  return (
    <div className="container ">
      <div className="PackageWrapper02">
        <div className="PackageWriteUp02">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              CleeQ
              <span className="LandingSUB2"> of Friends&nbsp;</span>
            </div>
            <div className="SubHead">
              The CleeQ-of-Friends caters to like-minded friends, ideal for
              coordinating hang-outs, committees for weddings, social events,
              birthdays, funerals, trips, and other occasions.
              <br />
              <br />
              {/* <span className="ReadMore" onClick={handleShow}>
          Know More about CleeQFamily...
        </span> */}
            </div>
          </div>

          <FundingButton />
        </div>
        <div className="PackageImg02">
          <img src="/assets/CF.png" alt="" className="PackImg02" />
        </div>
      </div>
    </div>
  );
}

export default Companion;
