import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";

function JoinDCleeQ() {
  return (
    <div>
  
      <center className="JoinTheCleeQHeading">Join the <span className="JoinTheCleeQTag">CleeQ</span></center>
    </div>
  );
}

export default JoinDCleeQ;
