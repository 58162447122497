import React from 'react'
import { Link } from "react-router-dom";
import "../../Components/NavBar/NavBar.css";

function NavBarList() {
  return (
        <div>
        <div className="NavBarWrapper">
          <div className="NavList1">
            <Link to="/" className="ClickLink">
              <div className="NavList">CleeQ</div>
            </Link>
            <Link to="/CleeQX" className="ClickLink">
              <div className="NavList">CleeQ - X</div>
            </Link>{" "}
            <Link to="/CleeQFunding" className="ClickLink">
              <div className="NavList">CleeQFunding</div>
            </Link>
            <Link to="/CleeQChat" className="ClickLink">
              <div className="NavList">CleeQ-Chat</div>
            </Link>
            <Link to="/Banking" className="ClickLink">
              <div className="NavList">Banking</div>
            </Link>
            <Link to="/Pricing" className="ClickLink">
              <div className="NavList">Pricing</div>
            </Link>
            <Link to="/WhoWeAre" className="ClickLink">
              <div className="NavList">Who We Are</div>
            </Link>
            {/* <Link to="/CleeQInvest" className="ClickLink">
              <div className="NavList">CleeQ-Invest</div>
            </Link> */}
          </div>
        </div>
      </div>
  )
}

export default NavBarList