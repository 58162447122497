import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";
import { ArrowForward } from "@material-ui/icons";

function OpenAccount() {
  return (
    <div>
      <button className="StartButton">
        <div>Open a CleeQ Account</div>

        <ArrowForward />
      </button>
    </div>
  );
}

export default OpenAccount;
