import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundingButton from "../CleeQFunding/FundingButton";

function Fam() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageImg">
          <img src="/assets/209.png" alt="" className="PackImg01" />
        </div>
        <div className="PackageWriteUp">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              CleeQ
              <span className="LandingSUB2">&nbsp;Family&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQ Family is an excellent remedy for your family's
              financial management dilemmas. It unites families by promoting
              trust, responsibility, and respect while facilitating
              contributions for your family up-keep.
              <br />
              <br />
              {/* <span className="ReadMore" onClick={handleShow}>
            Know More about CleeQFamily...
          </span> */}
            </div>
          </div>

          <FundingButton />
        </div>
      </div>
    </div>
  );
}

export default Fam;
