import React from 'react'
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";

function SearchBox() {
  return (
        <div>
        <div className="contanier SearchCont">
          <div className="row g-2">
            <div className="col-md-9">
              <div className="InputCasing">
                <input
                  className="InputSearch"
                  placeholder="Search for CleeQ by Username"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-3">
              <button className="Searchbtn20">Search</button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default SearchBox