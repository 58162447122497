import React from "react";
import { FiCheck } from "react-icons/fi";

function CleeQTransactions() {
  return (
    <div className="container ">
      <div className="row g-2">
        <div className="col-md-6">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;Family&nbsp;</span>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Free</div>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Subscription &#8358;&nbsp;FREE</div>
              </div>
              <div className="Servicefee">
                Service fee for any transaction within this CleeQ
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Fee &#8358;&nbsp;200</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2"> of Friends&nbsp;</span>
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Free for the first one year</div>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Subscription &#8358;&nbsp;2,999 Annually
                </div>
              </div>

              <div className="Servicefee">
                Service fee for any transaction within this CleeQ
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Fee &#8358;&nbsp;200</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;Basic&nbsp;</span>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Free for the first one year</div>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Subscription &#8358;&nbsp;5,500 Annually
                </div>
              </div>
              <div className="Servicefee">
                Service fee for any transaction within this CleeQ
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Fee &#8358;&nbsp;200</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;Pro&nbsp;</span>
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Free for the first one year</div>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Subscription &#8358;&nbsp;6,999 Annually
                </div>
              </div>
              <div className="Servicefee">
                Service fee for any transaction within this CleeQ
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">Fee &#8358;&nbsp;200</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;Funding&nbsp;</span>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">SignUp is &#8358;&nbsp;FREE</div>
              </div>
              <div className="Servicefee">
                Service fees for donations
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Fee - &#8358;&nbsp;200 from donor
                </div>
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Fee - 1.5% commission from receiver
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-up">
          <div className="PackageWriteUp33">
            <div className="PackageCoverUp">
              <div className="LandingHedings4000">
                CleeQ
                <span className="LandingSUB2">&nbsp;X&nbsp;</span>
              </div>
              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">SignUp is &#8358;&nbsp;FREE</div>
              </div>

              <div className="Servicefee">
                Service fees for any transaction to this CleeQ
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Fee - &#8358;&nbsp;200 from donor
                </div>
              </div>

              <div className="PricingOpt">
                <div className="PricingOptGuide">
                  <FiCheck className="checkIcon" />
                </div>
                <div className="SubHead20">
                  Fee - 1.5% commission from receiver
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CleeQTransactions;
