import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";


function Problems() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageWriteUp044">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Problems we are
              <span className="LandingSUB2">&nbsp;solving&nbsp;</span>
            </div>
            <div className="SubHead">
              <div className="SubHead">
                <b> Promoting Trust and Transparency</b> <br />
                Building trust and transparency within a social group or
                organization is essential for fostering strong relationships and
                ensuring accountability. <br /> <br />
                <b> Prevention of Public Fund Embezzlement</b> <br />
                Ensuring the security and integrity of group funds is critical,
                as unauthorized transactions or misuse can lead to financial
                losses and damage trust within the group.   <br /> <br />
                <b>Physical Presence Barriers</b><br />Being geographically separated from other group members can make
                it difficult to fulfil financial obligations.  
                {/* leveraging technology and implementing clear, transparent
                practices can effectively overcome these barriers.  */}
              </div>
            </div>
          </div>
        </div>
        <div className="PackageWriteUp044">
          <div className="SubHead">
            <b> Integration of Banking and Social Features</b> <br />
            Traditionally, managing finances and social interactions required
            separate platforms, leading to fragmentation and inconvenience for
            users.
            <br /> <br />
            <b>Real-Time Collective Account Activity Updates</b> <br />
            Traditional methods of account management often involve delays and
            administrative overhead, leading to a lack of visibility into
            collective account activities.
            <br /> <br />
            <b>Simplifying financial contributions, donations and more</b>{" "}
            <br />
            Traditional methods of account management often involve delays and
            administrative overhead, leading to a lack of visibility into
            collective account activities.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Problems;
