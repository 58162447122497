import React from "react";
import SearchBox from "../../Components/SearchBox";

function OurLanding() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            <div className="LandingHedings1" data-aos="zoom-in-up">
              Social Gathering
              <br />
              and <span className="LaNdBank">Banking</span>
            </div>

            <div className="CleeQSlogan">..the meeting place</div>
            <p className="Tittle">
              Create and manage your personal, family, friends, organizations,
              associations, unions, clubs, estates, tenants, community meetings,
              general group meetings, churches, NGOs, crowdfunding and more with
              CleeQ and start receiving money across the globe with just your
              Username, phone number or CleeQ ID.
            </p>
            {/* <div className="SubHead">
              The CleeQ-of-Friends caters to like-minded friends, ideal for
              coordinating hang-outs, committees for weddings, events,
              birthdays, funerals, trips, and other occasions.
            
            
            </div> */}
          </div>
          <div className="SearchWrapper">
            <SearchBox />
          </div>
          {/* <button className="StartButton"> Start CleeQ-Of-Friends Now</button> */}
        </div>
        <div className="LandImg02">
          <img src="/assets/OR.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default OurLanding;
