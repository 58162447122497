import React from "react";

function TrustIssues() {
  return (
    <div>
      <div className="container TrustCont">
        <div className="PackageWrapper">
          <div className="WrapperHeadings">
            <div className="TrustHeading">
              Have you observed or suspected any financial mismanagement within
              your association or group? &nbsp;
              <span className="PackageWrapSub09">
                Then you have to rely on CleeQ to protect your financial
                involvement within such group.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustIssues;
