import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundingButton from "../CleeQFunding/FundingButton";

function Basic() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageImg">
          <img src="/assets/BS.png" alt="" className="PackImg01" />
        </div>
        <div className="PackageWriteUp">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              CleeQ
              <span className="LandingSUB2">&nbsp;Basic&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQ Basic is for a wide range of groups, from community
              meetings to school associations, unions, clubs, age-grade, and
              more. We believe that transparent financial management cultivates
              trust and accountability to help members understand the purpose and use
              of their financial contributions.
              {/* <span className="ReadMore" onClick={handleShow}>
            Know More about CleeQFamily...
          </span> */}
            </div>
          </div>

          <FundingButton />
        </div>
      </div>
    </div>
  );
}

export default Basic;
