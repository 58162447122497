import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function StayConnected() {
  return (
    <>
      <div className="container-fluid">
        <center className="LandingHedings5055">
          Join the CleeQ and  stay
          <span className="LandingSUB505">
            &nbsp;Connected 
          </span>
          <center className="WhySub">
            Be more specific, create and organize your chatroom easily
            </center>
        </center>
       

       
      </div>
    </>
  );
}

export default StayConnected;
