import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import FundingButton from "./FundingButton";

function Schoolsupport() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageImg">
          <img src="/assets/Funding/SS.png" alt="" className="PackImg01" />
        </div>
        <div className="PackageWriteUp">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              School
              <span className="LandingSUB2">&nbsp;Fundraising&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQ offers a modern and efficient solution for educational
              institutions to raise funds and support various initiatives. Our
              secure donation process ensures transparency and accountability,
              building trust with donors.
            </div>
          </div>

          <FundingButton />
        </div>
      </div>
    </div>
  );
}

export default Schoolsupport;
