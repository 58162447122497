import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";
import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  InsertInvitationOutlined,
  LockOpenOutlined,
} from "@material-ui/icons";

function JoinOver() {
  return (
    <div>
      <div
        className="container WorryLap StpesContainer overflow-hidden"
        data-aos="fade-up"
      >
        <div className="WorryCont">
          <center className="LandingHedings">
            <div className="LandingHedings101">
              Join over 60 million CleeQ users to effortlessly send and 
              receive funds and effectively manage your groups
            </div>
          </center>
          <center className="JoinEasy">It's Easy! Simple! and Fun!</center>
        </div>
      </div>
      <div className="container ">
        <div className="WorryCont">
          <div className="container p-0 CleeQLanding2 overflow-hidden">
            <div className="row g-5">
              <div className="col-lg-3">
                <div>
                  <center className="HowItWoksSteps">
                    <div className="HowItWoksLogo">
                      <LockOpenOutlined className="StartIcon" />
                    </div>
                    <div>
                      <div className="HowItWoksHeadings">Sign Up</div>
                      <div className="HowItWoksSup">
                        Sign Up on Web, IOS or Android and have your personal or
                        business Account Number Instantly. Start sending and
                        receiving payments
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-lg-3">
                <div>
                  <center className="HowItWoksSteps">
                    <div className="HowItWoksLogo">
                      <AddCircleOutlineOutlined className="StartIcon" />
                      {/* <img src="/assets/01.png" alt="" className="HowIcon" /> */}
                    </div>
                    <div>
                      <div className="HowItWoksHeadings">Create a CleeQ</div>
                      <div className="HowItWoksSup">
                        Choose from any of our packages to create a CleeQ that
                        suites your community goals
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-lg-3">
                <div>
                  <center className="HowItWoksSteps">
                    <div className="HowItWoksLogo">
                      <InsertInvitationOutlined className="StartIcon" />
                    </div>
                    <div>
                      <div className="HowItWoksHeadings">
                        Invite your members
                      </div>
                      <div className="HowItWoksSup">
                        Invite your CleeQ members and organize your meetings. Assign signatories to your
                        CleeQ Account and conduct transactions transperently
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-lg-3">
                <div>
                  <center className="HowItWoksSteps">
                    <div className="HowItWoksLogo">
                      <CheckCircleOutlineOutlined className="StartIcon" />
                    </div>
                    <div>
                      <div className="HowItWoksHeadings">
                        Manage your CleeQ
                      </div>
                      <div className="HowItWoksSup">
                      You can now manage your CleeQ finances more efficiently and transparently
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinOver;
