import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import { ShareOutlined, VerifiedUser } from "@material-ui/icons";
import SearchBox from "../../Components/SearchBox";
import VerifyUserAndCleeQ from "../../Components/VerifyUserAndCleeQ";
import { FiBell, FiMessageCircle, FiShare2 } from "react-icons/fi";

function FundingSearch() {
  return (
    <div className="JoinCont3 ">
      <div className="FindCont">
        <center className="CampaignHeadings1">Find your CleeQ and Join</center>
        {/* <center className="Tittle40">
                      Search for cleeQs is Only for CleeQ-X and CleeQFunding.
                    </center> */}
      </div>
      <br /> <br /> <br />
      <div className=" container p-0 SearchWrapper">
        <SearchBox />
      </div>{" "}
      <br /> <br /> <br />
      <div className="container PacLanding overflow-hidden p-0">
        <div className="row gy-4">
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">Feed the nation foundation</div>
                  <div className="Username__Amount">
                    <div className="Username__A">@FeedTNat </div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">Omenaa Foundation</div>
                  <div className="Username__Amount">
                    <div className="Username__A">@Omenaa</div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">Generado Houses</div>
                  <div className="Username__Amount">
                    <div className="Username__A">@GeneradoHouses </div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">House Construction</div>
                  <div className="Username__Amount">
                    <div className="Username__A">@HouseConstruction </div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">DoMyWork Studios</div>
                  <div className="Username__Amount">
                    <div className="Username__A">@DoMyWorkStudios </div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="Back__Ground4">
              <div className="Package__Details">
                <div className="Package__Img">
                  <img
                    src="/assets/UserAvater.png"
                    alt=""
                    // className="Img__Package"
                    height="50"
                    width="50"
                  />
                </div>

                <div className="Clique__Name">
                  <div></div>

                  <div className="C__Name">Among professionals </div>
                  <div className="Username__Amount">
                    <div className="Username__A">@Amongprofessionals </div>
                  </div>

                  <div className="User__D">
                    <div className="User__1D1">YURT5609</div>

                    <div className="User__1D1">300 Followers</div>
                  </div>
                  <div className="Exit__Report__Details ">
                    <div className="Clique__Footer">Donate</div>

                    <FiShare2 className="Share__Icon" />

                    <div className="Clique__Footer">Report</div>
                    <div className="Clique__Footer">Join</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundingSearch;
