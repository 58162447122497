// import React from "react";
import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import SearchBox from "../../Components/SearchBox";

import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  InsertInvitationOutlined,
  LockOpenOutlined,
} from "@material-ui/icons";
import Desire from "../CleeQ/Desire";
import Fam from "../CleeQ/Fam";
import Companion from "../CleeQ/Companion";
import Basic from "../CleeQ/Basic";
import Pro from "../CleeQ/Pro";
import Worry from "../CleeQ/Worry";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import DIsplayedCleeQs from "../../Components/DIsplayedCleeQs";
import OurServices from "../CleeQ/OurServices";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import X from "../CleeQX/X";
import JoinOver from "../../Components/JoinOver";
import XServices from "../CleeQX/XServices";
import Invest from "./Invest";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function CleeQInvest() {
  const classes = useStyles();
  return (
    <div>
    <div className="CleeQBody">
      <header className="CleeQSticky">
        <NavBar />
      </header>
      {/* <div className="CleeQBody2">
        <p>
          By default, the background color of the document is "tan". If the
          screen size is 992px or less, the color will change to "blue". If it
          is 600px or less, it will change to "olive".
        </p>
      </div> */}
      <section>
        {/* <CleeQLandingPage /> */}
        <div>
          <Invest/>
        </div>
        <br /> <br /> <br /> <br />
        <div data-aos="fade-up">
          <DIsplayedCleeQs />
        </div>
        <div data-aos="fade-up">
          <JoinOver />
        </div>
       
        <div data-aos="fade-up">
          <JoinTheCleeQ />
        </div>
        <br /> <br /> <br /> <br />
        <div data-aos="fade-up">
          <XServices />
        </div>
        {/* 
        

        <div data-aos="fade-up">
          <JoinTheCleeQ />
        </div>

        <div data-aos="fade-up">
          <Accountability />
        </div> */}
      </section>
      <footer className="CleeQFooter">
        <Footer />
      </footer>
    </div>
  </div>
  )
}

export default CleeQInvest