import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function CleeQIntroHeading() {
  return (
    <div>
      <div className="container ">
        <div className="PackageWrapper">
          <div className="WrapperHeadings">
            <div className="PackageWrapperHeadings">
              Have you already created a group with other social apps? No worries...
            </div>
            <div className="PackageWrapSub">
              It's time for you to step up your game with a more secure and
              trusted way to manage your group's finances and activities.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CleeQIntroHeading;
