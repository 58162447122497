import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import FundingButton from "./FundingButton";

function PersonalSupport() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageImg">
          <img src="/assets/Funding/POP.png" alt="" className="PackImg01" />
        </div>
        <div className="PackageWriteUp">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Individual
              <span className="LandingSUB2">&nbsp;Fundraising&nbsp;</span>
            </div>
            <div className="SubHead">
              Individuals have the opportunity to establish a CleeQFunding
              account, enabling you to receive and send funds, enhance your
              global visibility using just your username. 
             
            </div>
          </div>

          <FundingButton />
        </div>
      </div>
    </div>
  );
}

export default PersonalSupport;
