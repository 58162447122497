import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function CleeQChatBtn() {
  return (
    <div>
      <button className="StartBTN1">Join the CleeQ</button>
    </div>
  );
}

export default CleeQChatBtn;
