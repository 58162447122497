import React from "react";
import SearchBox from "../../Components/SearchBox";

function AboutUs() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            <div className="LandingHedings1" data-aos="zoom-in-up">
              How we&nbsp;<span className="LaNdBank">started</span>
            </div>

            <p className="AboutHeading">
              In my tight-knit group of 24 friends, we foster a strong sense of
              camaraderie through an active WhatsApp group. Our dual vision is
              to embrace life to the fullest and provide mutual support during
              crises. However, recurring issues with fund mismanagement hindered
              our collective efforts, a challenge shared by many other groups.{" "}
              <br />I realized the popular social apps lacked the transparency
              for efficient financial management and group detailed activities.
              This led me to envision a solution that streamlines our
              interactions and improves financial transparency.{" "}
              {/* Driven by this, we developed CleeQ24—an app transforming our group
              dynamics. It embodies our values of fun, friendship, and timely
              aid while introducing unprecedented financial transparency. <br />
              <br /> */}
              Through determined effort, we developed CleeQ. An app that handles
              group activities, simplify meetings, and enhances financial
              management. CleeQ is an innovative response to challenges faced by
              us and many other groups.
              {/* Our journey evolved from friends seeking
              solutions to a tool bridging friendship and finance. It teaches
              that challenges breed innovation, as shared problems lead to
              solutions benefiting broader communities.  */}
              <br />
              <br />
              Welcome to a new era of social interaction and banking. Welcome to
              CleeQ.
              <br />
              <br />
              <b> Chilaka Nnamdi </b> <br />
              <b>Founder</b>
            </p>
          </div>
          <div className="SearchWrapper"></div>
        </div>
        <div className="LandImg02">
          <img src="/assets/ppo.png" alt="" className="LandingImg" />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="LandingWrapper">
        <div className="LandImg02">
          <img src="/assets/c2.png" alt="" className="LandingImg" />
        </div>
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            <div className="LandingHedings1" data-aos="zoom-in-up">
              About&nbsp;<span className="LaNdBank">Us</span>
            </div>

            <p className="AboutHeading">
              We are a social gathering and banking app that offers a unique
              solution by seamlessly merging banking functionalities with social
              features. From organizing meetings, events, and projects to
              managing group members, connecting members through chat or
              messaging, and simplifying group membership registration,
              contributions, donations, fines, and loans. <br />
              Our app aims to promote trust and transparency among members of a
              specific social group. Our centralized alert system keeps members
              informed about their collective account activities in real-time,
              eliminating the need for traditional yearly account statements or
              reviews by the group's executives. <br />
              With our multi-signatory transaction system, we prevent fund
              embezzlement by requiring multiple approvals for transactions. Our
              App will be the first of its kind to be listed among the likes of
              WhatsApp, Facebook, Instagram, X, and others. As you display your
              social media handles on your various online platforms, you will
              also display your CleeQ handle to receive funds with just your
              username. <br /> <br />
              {/* We are redefining the standard narrative from "join our group" to "join the CleeQ." */}
              <b>...So, what are you waiting for? why not Join the CleeQ</b>
            </p>
          </div>
          <div className="SearchWrapper"></div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
