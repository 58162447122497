
import React, { useEffect } from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import Worry from "./Worry";
import Desire from "./Desire";
import Fam from "./Fam";
import Companion from "./Companion";
import Basic from "./Basic";
import Pro from "./Pro";
import JoinTheCleeQ from "./JoinTheCleeQ";
import OurServices from "./OurServices";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import CleeQIntroHeading from "./CleeQIntroHeading";
import TrustIssues from "./TrustIssues";
import OurLanding from "./OurLanding";
import Aos from "aos";
import "aos/dist/aos.css";
import JoinOver from "../../Components/JoinOver";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));



function CleeQ() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.scrollTo(0, 0);  // This will scroll the page to the top when the component mounts
  }, []);

  const classes = useStyles();

  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>

        <section>
          <div>
            <OurLanding />
          </div>
          <div data-aos="fade-up">
            <Worry />
          </div>
          <div data-aos="fade-up">
            <TrustIssues />
          </div><br/><br/><br/><br/><br/><br/>
          
          <JoinOver />
          <div data-aos="fade-up">
            <Desire />
          </div>
          <div data-aos="fade-up">
            <CleeQIntroHeading />
          </div>
          <div data-aos="fade-up">
            <Fam />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <Companion />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <Basic />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div data-aos="fade-up">
            <Pro />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
    
          <div data-aos="fade-up">
            <OurServices />
          </div>
          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default CleeQ;
