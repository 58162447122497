import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import CleeQXBtn from "../CleeQX/CleeQXBtn";
import CleeQInvestBtn from "./CleeQInvestBtn";
function Invest() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            {/* <div className="LandingHedings4000">
              Social Gathring And<br/>
              <span className="LandingSUB2">Banking&nbsp;</span>
            </div> */}
            <div className="LandingHedings1" data-aos="zoom-in-up">
              CleeQ
              <span className="LaNdBank">Invest</span>
            </div>

            <div className="CleeQSlogan">
            Your financial goals are within reach.
            </div>
            <p className="Tittle">
            Don't just save; invest! Our FinTech app paves the way for
                wealth creation and financial freedom <br />
              <br />
              Upon registration, access your investment dashboard and witness
                your investment portfolio flourish.
            </p>
            {/* <div className="SubHead">
            The CleeQ-of-Friends caters to like-minded friends, ideal for
            coordinating hang-outs, committees for weddings, events,
            birthdays, funerals, trips, and other occasions.
          
          
          </div> */}
          </div>
          <div className="SearchWrapper">
            <CleeQInvestBtn />
          </div>
          {/* <button className="StartButton"> Start CleeQ-Of-Friends Now</button> */}
        </div>
        <div className="LandImg02">
          <img src="/assets/Funding/RO.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default Invest;
