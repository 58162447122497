import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import CleeQXBtn from "./CleeQXBtn";

function X() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            {/* <div className="LandingHedings4000">
              Social Gathring And<br/>
              <span className="LandingSUB2">Banking&nbsp;</span>
            </div> */}
            <div className="LandingHedings1" data-aos="zoom-in-up">
              CleeQ-X For <br />
              Religious&nbsp;
              <span className="LaNdBank">Gathering</span>
            </div>

            <div className="CleeQSlogan">
              ...Digital Worship, Giving with Heart
            </div>
            <p className="Tittle">
              CleeQ-X makes paying and tracking your tithe, offering, and seed
              more convenient than ever, from anywhere and at any time.
              Supporting any religious body is as simple as following their
              CleeQ-X handle. <br />
              <br />
              Create a CleeQ-X handle for your religious body today and receive
              extra-ordinary financial support from anyone.
            </p>
            {/* <div className="SubHead">
            The CleeQ-of-Friends caters to like-minded friends, ideal for
            coordinating hang-outs, committees for weddings, events,
            birthdays, funerals, trips, and other occasions.
          
          
          </div> */}
          </div>
          <div className="SearchWrapper">
            <CleeQXBtn />
          </div>
          {/* <button className="StartButton"> Start CleeQ-Of-Friends Now</button> */}
        </div>
        <div className="LandImg02">
          <img src="/assets/WOR.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default X;
