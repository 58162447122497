import {
  AccountTree,
  AssignmentReturnedOutlined,
  DonutSmallOutlined,
  EmojiEventsOutlined,
  GamesOutlined,
  MeetingRoom,
  ThumbDownOutlined,
} from "@material-ui/icons";
import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function OurServices() {
  return (
    <div className="container JoinCont3">
      <div className="PacCont">
        <div className="container p-0 PacLanding overflow-hidden ">
          <div className="TaskWrapper">
            <center className="WhyChooseWrapper">
              <center className="WhyChooseContainer">
                Some of our special features include
              </center>{" "}
              {/* <center className="WhySub">
                We've provided a list of contents to assist you in arranging
                your CleeQ effectively.
              </center> */}
            </center>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row">
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <MeetingRoom className="AccIcon" />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Meetings</div>
                            <div className="ACCSub">
                              Your time, your agenda, we make your CleeQ
                              meetings matter.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <AccountTree className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Projects</div>
                            <div className="ACCSub">
                              Create and monitor your CleeQ project progress.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <EmojiEventsOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Events</div>
                            <div className="ACCSub">
                              Do you have an event coming up? create the event,
                              add ticket fee.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row">
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <ThumbDownOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Fines</div>
                            <div className="ACCSub">
                              Honoring agreements, avoiding penalties. CleeQ24
                              offers penalty options for members who fail to
                              meet their obligations.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <GamesOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Loans</div>
                            <div className="ACCSub">
                              Apply for a loan within your CleeQ account and
                              effortlessly add your repayment plan.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <DonutSmallOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Contributions</div>
                            <div className="ACCSub">
                              Contribute monthly or yearly to your CleeQ.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row">
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Donations</div>
                            <div className="ACCSub">
                              Organize your donations by creating multiple
                              donation handles, allowing you to receive funds
                              according to your specific needs or preferences.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Signatories </div>
                            <div className="ACCSub">
                              Prevent fund embezzlement by adding multiple
                              signatories to your CleeQ account to confirm any
                              fund withdrawals.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
