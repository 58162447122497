import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import OpenAccount from "../../Components/OpenAccount";

function Bank() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            <div className="LandingHedings1" data-aos="zoom-in-up">
              Personal and Business&nbsp;
              <span className="LaNdBank">Banking</span>
            </div>

            <div className="CleeQSlogan">
              ...Banking for Business, Banking for Life.
            </div>
            <p className="Tittle">
              Take control of your personal and business banking using CleeQ24,
              enabling global money receipt using your username, account number,
              Phonenumber, or your unique ID.
            </p>
          </div>
          <div>
            <div className="SearchWrapper">
              <OpenAccount />
            </div>
          </div>

          {/* <button className="StartButton"> Start CleeQ-Of-Friends Now</button> */}
        </div>
        <div className="LandImg02">
          <img src="/assets/CleeQPix/pank.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default Bank;
