import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function CleeQTransacitionHeading() {
  return (
    <div className="container p-0">
      <center className="PackageWrapper022">
        <div className="PackageWriteUp023">
          <div className="PackageCoverUp2">
            <div className="LandingHedings4000">
              Outflow Transaction
              <span className="LandingSUB2">&nbsp;Pricing</span>
            </div>
            <div className="SubHead">
              <div className="LandingHedings4000"> ₦ &nbsp;15:00</div>
            </div>
            <div className="SubHead">
              Outflow transactions for your personal and businesss banking
            </div>
          </div>
          {/* <div className="LandingHedings4000">&#8358;&nbsp;15:00</div> */}
        </div>
       
      </center>
    </div>
  );
}

export default CleeQTransacitionHeading;
