import React from 'react'
import Navbar from "react-bootstrap/Navbar";
import "../Pages/CleeQ.css";

function CleeQLogo() {
  return (
        <div>
        <Navbar.Brand>
          <img src="/assets/Logonew.png" alt="" className="CleeQ24__Loog" />
        </Navbar.Brand>
      </div>
  )
}

export default CleeQLogo