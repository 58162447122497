import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import FundingButton from "./FundingButton";

function Ngo() {
  return (
    <div className="container ">
      <div className="PackageWrapper02">
        <div className="PackageWriteUp02">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              NGO
              <span className="LandingSUB2"> Fundraising&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQ app provides a dynamic platform for nonprofits to mobilize
              support and resources for their causes. We empowers NGOs to
              achieve their fundraising goals with ease, enabling them to make a
              meaningful difference in their communities and beyond.
            </div>
          </div>

          <FundingButton />
        </div>
        <div className="PackageImg02">
          <img src="/assets/Funding/VL.png" alt="" className="PackImg02" />
        </div>
      </div>
    </div>
  );
}

export default Ngo;
