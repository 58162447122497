import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";

function CleeQFunds() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <div className="LandingCoverUp">
            {/* <div className="LandingHedings4000">
            Social Gathring And<br/>
            <span className="LandingSUB2">Banking&nbsp;</span>
          </div> */}
            <div className="LandingHedings1" data-aos="zoom-in-up">
              CleeQ
              <span className="LaNdBank">Funding</span>
            </div>

            <div className="CleeQSlogan">
              "Crowdfunding: Fueling Ambition, Driving Success"
            </div>
            <p className="Tittle">
              CleeQFunding also known as crowd and personal funding enable you
              to expand your reach on a global scale, enhancing visibility and
              attracting potential financial supporters solely through your
              CleeQ user handle.
            </p>
            {/* <div className="SubHead">
          The CleeQ-of-Friends caters to like-minded friends, ideal for
          coordinating hang-outs, committees for weddings, events,
          birthdays, funerals, trips, and other occasions.
        
        
        </div> */}
          </div>
          <div>
            <div className="SearchWrapper">
              <FundRaisingBtn />
            </div>
          </div>

          {/* <button className="StartButton"> Start CleeQ-Of-Friends Now</button> */}
        </div>
        <div className="LandImg02">
          <img src="/assets/gp.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default CleeQFunds;
