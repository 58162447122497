import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import X from "./X";
import XSearch from "./XSearch";
import XServices from "./XServices";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function CleeQX() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.scrollTo(0, 0); // This will scroll the page to the top when the component mounts
  }, []);

  const classes = useStyles();
  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>

        <section>
          <div>
            <X />
          </div>
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <XSearch />
          </div>
          {/* <div data-aos="fade-up">
            <JoinOver />
          </div> */}
          <br /> <br /> <br /> <br />
          <div data-aos="fade-up">
            <XServices />
          </div>
          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>
          {/* 
          

          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>

          <div data-aos="fade-up">
            <Accountability />
          </div> */}
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default CleeQX;
