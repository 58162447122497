import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function Desire() {
  return (
    <div className="container WorryLap">
      <div className="WorryCont">
        <div className="container p-0 CleeQLanding overflow-hidden">
          <div className="Dondal">
            <div className="Dondal2">
              <div className="DesireConatiner">
                <div className="DesireC ">
                  <div className="JSpport">
                    If you desire
                    <div className="JSpport2">
                      Financial Transperency, Accountability, Efficiency,
                      Convinience, Privacy, Trust &nbsp;
                      <span className="JSpport">
                        and more to manage any group you belong to, then you
                        need CleeQ.
                        {/* <br />
                        <br />
                        <br />
                       */}
                      </span>
                      {/* <div>
                <GetStarted />
              </div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="REALCover">
                  <img src="/assets/AS.png" alt="" className="Samples" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Desire;
