import React from "react";
import JoinDCleeQ from "../../Components/JoinDCleeQ";
// import WhyUs from "../../Components/WhyUs";

function JoinTheCleeQ() {
  return (
    <div className="container JoinCont">
      <div className="PacCont">
        <div className="container p-0 PacLanding overflow-hidden ">
          <div className="row">
            <div className="col-12">
              <div className="p-3">
                <JoinDCleeQ />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinTheCleeQ;
