import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function CleeQXBtn() {
  return (
        <div>
        <button className="StartBTN1">Create your CleeQ-X handle</button>
      </div>
  )
}

export default CleeQXBtn