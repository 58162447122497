import React from "react";
// import "../../Pages/Home.css";

function BankInStyle() {
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div className="container">
          <div className="PackChat20 " data-aos="fade-up">
            <div className="ImgChat20">
              <div>
                <div className="LandingHedings50505">
                  Bank In&nbsp;
                  <span className="LandingSUB505009">Style</span>
                </div>
                <div className="CleeQSlogan505009">
                  Explore Our Banking Options
                </div>

                <p className="Tittle2">
                  Banking in style and on your own terms, Banking Beyond
                  Boundaries!"
                </p>
              </div>
              <br />
              <br />
              <button className="StartBTN1">Join The CleeQ For Free</button>
            </div>
            <div className="WriteUpChat009">
              <img src="/assets/fg.png" alt="" className="Friends5009" />
              <div className="DesinCover5656779686"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankInStyle;
