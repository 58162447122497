import {
  AccountTree,
  AssignmentReturnedOutlined,
  BlurCircularOutlined,
  CardMembershipOutlined,
  ColorizeOutlined,
  DashboardOutlined,
  DonutSmallOutlined,
  EmojiEventsOutlined,
  FaceOutlined,
  FingerprintOutlined,
  GamesOutlined,
  MeetingRoom,
  RepeatOneOutlined,
  SupervisorAccountOutlined,
  ThumbDownOutlined,
} from "@material-ui/icons";
import React from "react";

function WhyBankWithUs() {
  return (
    <div>
      {" "}
      <div className="container JoinCont3">
        <div className="PacCont">
          <div className="container p-0 PacLanding overflow-hidden ">
            <div className="TaskWrapper">
              <center className="WhyChooseWrapper">
                <center className="WhyChooseContainer">
                Some of our special features include






                </center>{" "}
                <center className="WhySub">
                  Banking in style with username, phone number, account number
                  and
                </center>
              </center>
              <br /> <br />
              <div className="container p-0  overflow-hidden">
                <div className="row">
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <EmojiEventsOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">UserName</div>
                              <div className="ACCSub">
                                Share your CleeQhandle to all social app to
                                receive money more conviniently.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <AssignmentReturnedOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Account Number</div>
                              <div className="ACCSub">
                                You have the choice to receive payment with your
                                CleeQ bank details
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <FaceOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Phone Number.</div>
                              <div className="ACCSub">
                                Accept payment with your Phone number
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyBankWithUs;
