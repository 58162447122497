import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";
import { ShareOutlined } from "@material-ui/icons";
import SearchBox from "./SearchBox";

function DIsplayedCleeQs() {
  return (
    <div className="JoinCont3 ">
      <div className="container p-0 PacLanding overflow-hidden ">
        <div className="row">
          <div className="col-12">
            <div className="p-3">
              <>
                <div className="FindCont">
                  <center className="CampaignHeadings1">
                    Find Your CleeQ And Join
                  </center>
                  <center className="Tittle40">
                    Search for cleeQs is Only for CleeQ-X and CleeQFunding.
                  </center>
                </div>

                <div className="container px-4 CleeQBOX">
                  <div className="SearchWrapper">
                    <SearchBox />
                  </div>
                  <br /> <br /> <br />
                  <div className="row gx-4">
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              CleeQ24
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @CleeQ24
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">CLEEQ240000</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">40M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>

                              {/* <div className="CleeQ__Footer">Follow</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Redeem Christain Church of God Abuja
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @RedeemGlobal
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">OOUY84904JD</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">6.89M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Donate For People In Ukraine
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @Ukraine
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">SOU87YHTGF</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">600.9M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Streams of Joy international.
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @Nsppd
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">DOIEU9JUJE90</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">60M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Dunamis International
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @Dunamis
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">UIEO98EJUDE</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">300K Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              St.Mark Catholic Church Abuja
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @St.Mark
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">MASDUJGTT09</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">7K Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              30BG
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @30bg_geng
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">UJHDJD849804</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1"> 11Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4  mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Chilaka-Ukpo Foundation
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @ChilakaUpko_Foundation
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">ABSUHSIJEU</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">800M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 mb-3">
                      <div className="Back__Ground4">
                        <div className="Package__Indicator">
                          <div></div>
                          <div>Sponsored</div>

                          {/* <MoreVert className="Wallet__Not" /> */}
                        </div>

                        <div className="Package__Details">
                          <div className="Package__Img">
                            <img
                              src="/assets/UserAvater.png"
                              alt=""
                              className="Img__Package"
                            />
                          </div>

                          <div className="CleeQ__Name">
                            {/* <Link to="/CleeQExclusiveDashboard" className="CleeQ__Link"> */}
                            <div
                              className="C__Name"
                              //       onClick={() => setExclusivenotjoinedShow(true)}
                            >
                              Abia State University
                            </div>

                            {/* </Link> */}

                            <div className="Username__Amount">
                              <div className="Username__A">
                                @Abia_State
                                {/* <VerifyUserAndCleeQ /> */}
                              </div>
                              {/* <Walletbalance /> */}
                            </div>
                            <div className="User__D">
                              <div className="User__1D1">DOKIUJYNEJE</div>
                              {/* <div>
                         <WhatsApp className="Wallet__Icon" />
                         <span className="User__1D">2</span>
                       </div> */}
                              <div className="User__1D1">8M Funders</div>
                            </div>
                            <div className="Exit__Report__Details ">
                              {/* <div
                         className="CleeQ__Footer "
                         onClick={() => setExitShow(true)}
                       >
                         Exit
                       </div> */}
                              <div
                                className="CleeQ__Footer "
                                // onClick={() => setMakedonationShow(true)}
                              >
                                Donate
                              </div>

                              <div className="Share__Btn">
                                <ShareOutlined className="Share__Icon" />
                              </div>
                              <div className="CleeQ__Footer ">Report</div>
                              <div className="CleeQ__Footer">Follow</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Exit show={exitShow} onClose={handleExit} />
         <Donate
           show={makedonationShow}
           onClose={handleMakedonation}
          
           onOpen={() => setMakedonationShow(true)}
         />
         <NotJoined
           show={exclusivenotjoinedShow}
           onClose={handleExclusivenotjoined}
          
           onOpen={() => setExclusivenotjoinedShow(true)}
         />
         <NotJoinedFunding
           show={fundingnotjoinedShow}
           onClose={handleFundingnotjoined}
        
           onOpen={() => setFundingnotjoinedShow(true)}
         />
         <NotJoinedCleeQ
           show={CleeQnotjoinedShow}
           onClose={handleCleeQnotjoined}
          
           onOpen={() => setCleeQnotjoinedShow(true)}
         /> */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DIsplayedCleeQs;
