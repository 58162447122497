import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function Worry() {
  return (

    <div className="container WorryLappings">
    <div className="PackageWrapper">
      <div className="PackageImg">
        <img src="/assets/WO.png" alt="" className="PackImg01" />
      </div>
      <div className="PackageWriteUp">
        <div className="PackageCoverUp">
          <div className="LandingHedings4000">
          Belonging to a group without financial trust can be  
            <span className="LandingSUB2">&nbsp;troubling.&nbsp;</span>
          </div>
          
       
        </div>

        <div className="SubHead">
              CleeQ enhances group financial management, promoting
              transparency and trust. It empowers Members 
              with a clear view of their financial engagement, enabling
              tracking of Contributions, Donations, Fines, Loans, Events,
              Meetings, Projects, Outflows, and Inflows within a specific
              CleeQ. 
            <br />
            <br />
            {/* <span className="ReadMore" onClick={handleShow}>
          Know More about CleeQFamily...
        </span> */}
          </div>
      </div>
    </div>
  </div>


  );
}

export default Worry;


