import React from "react";
import "../Pages/CleeQ.css";
import "../Pages/Responsive.css";

function FundRaisingBtn() {
  return (
    <div>
      <button className="StartBTN1">Start Your Fundraising Now</button>
    </div>
  );
}

export default FundRaisingBtn;
