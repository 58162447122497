import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";


function OutflowPricing() {
  return (
    <div className="container ">
      <center className="PackageWrapper022">
        <div className="PackageWriteUp44">
          <div className="PackageCoverUp2">
            <div className="LandingHedings4000">
              Affordable, fair
              <span className="LandingSUB2">&nbsp;pricing</span>
            </div>
            {/* <div className="SubHead">
              CleeQ24 make money when a service is successfully rendered
            </div> */}
          </div>
          {/* <div className="LandingHedings4000">&#8358;&nbsp;15:00</div> */}
        </div>
      
      </center>
    </div>
  );
}

export default OutflowPricing;
