import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";


function OurVision() {
  return (
    <div className="container ">
      <div className="PackageWrapper02">
        <div className="PackageWriteUp02">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Our
              <span className="LandingSUB2"> Vision&nbsp;</span>
            </div>
            <div className="SubHead">
              Our vision is to revolutionize social financial management by
              fostering transparency, trust, and accountability through
              innovative solutions, thereby enhancing the quality of group
              interactions and financial transactions for communities worldwide.
            </div>
          </div>
        </div>
        <div className="PackageImg02">
          <img src="/assets/V2.png" alt="" className="PackImg02" />
        </div>
      </div>
    </div>
  );
}

export default OurVision;
