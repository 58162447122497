import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundingButton from "../CleeQFunding/FundingButton";

function OurCoreValues() {
  return (
    <div className="container ">
      <div className="PackageWrapper">
        <div className="PackageWriteUp044">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Our Core
              <span className="LandingSUB2">&nbsp;Values&nbsp;</span>
            </div>
            <div className="SubHead">
              <b>Transparency:</b> We commit to open and clear communication in
              all financial transactions, ensuring that every member can trust
              the process. <br /> <br />
              <b>Trust: </b>
              We build and maintain trust within communities by upholding the
              highest standards of integrity and reliability in our services.{" "}
              <br /> <br />
              <b>Accountability: </b>
              We take responsibility for our actions and decisions, ensuring
              that all transactions are handled with the utmost diligence and
              fairness. <br /> <br />
            </div>
          </div>
        </div>
        <div className="PackageWriteUp044">
          <div className="SubHead">
            <b>Innovation:</b> We continuously seek and implement cutting-edge
            solutions to enhance the efficiency and effectiveness of financial
            management within groups. <br /> <br />
            <b>Community:</b> We prioritize the well-being and support of our
            user communities, fostering strong relationships and mutual support.{" "}
            <br /> <br />
            <b> Empowerment:</b> We empower users by providing tools and resources that enhance their
            financial literacy and confidence.
            <br /> <br />
            <b> Collaboration:</b> We believe in the power of working together
            and encourage collaborative efforts to achieve common goals and
            overcome challenges.
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurCoreValues;
