import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import {
  AccountTree,
  AssignmentReturnedOutlined,
  BlurCircularOutlined,
  CardMembershipOutlined,
  ColorizeOutlined,
  DashboardOutlined,
  DonutSmallOutlined,
  EmojiEventsOutlined,
  FaceOutlined,
  FingerprintOutlined,
  GamesOutlined,
  MeetingRoom,
  RepeatOneOutlined,
  SupervisorAccountOutlined,
  ThumbDownOutlined,
} from "@material-ui/icons";

function FundingServices() {
  return (
    <div>
      {" "}
      <div className="container JoinCont3">
        <div className="PacCont">
          <div className="container p-0 PacLanding overflow-hidden ">
            <div className="TaskWrapper">
              <center className="WhyChooseWrapper">
                <center className="WhyChooseContainer">
                  Explore Our Services
                </center>{" "}
                <center className="WhySub">
                  We've provided a list of contents to assist you in arranging
                  your CleeQ effectively.
                </center>
              </center>
              <br /> <br />
              <div className="container p-0  overflow-hidden">
                <div className="row">
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <EmojiEventsOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Donations</div>
                              <div className="ACCSub">
                                From Heart to Hand: Your Donation Matters
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <AssignmentReturnedOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Events</div>
                              <div className="ACCSub">
                                Create your event, Add ticket fee.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <FaceOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Smart Dashboard</div>
                              <div className="ACCSub">
                                Group Synergy: Where Inflow Meets Outflow.Track
                                All transactions.
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br />
              <div className="container p-0  overflow-hidden">
                <div className="row">
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <CardMembershipOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Membership</div>
                              <div className="ACCSub">
                                Add your CleeQ Registration fee for all Members
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <BlurCircularOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Username</div>
                              <div className="ACCSub">
                                Elevate your transactions with your username,
                                your digital fingerprint in cyberspace{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="">
                      <div className="container-fluid p-0">
                        <div className="row g-2">
                          <div className="col-xl-3">
                            <div className="IconCont1">
                              <SupervisorAccountOutlined className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                          <div className="col-xl-9">
                            <div className="">
                              <div className="ACCHeading">Admin Management</div>
                              <div className="ACCSub">
                                Add Multiple Admin to manage your CleeQ affairs
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundingServices;
