import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import FundRaisingBtn from "../../Components/FundRaisingBtn";
import FundingButton from "./FundingButton";

function MedicalSupport() {
  return (
    <div className="container ">
      <div className="PackageWrapper02">
        <div className="PackageWriteUp02">
          <div className="PackageCoverUp">
            <div className="LandingHedings4000">
              Medical
              <span className="LandingSUB2">&nbsp;Fundraising&nbsp;</span>
            </div>
            <div className="SubHead">
              CleeQ enables medical fundraising, aiding those with healthcare
              expenses. Through community support and transparent campaigns, it
              eases financial burdens, granting access to crucial treatments .
             
            </div>
          </div>

          <FundingButton />
        </div>
        <div className="PackageImg02">
          <img src="/assets/Funding/MEDY.png" alt="" className="PackImg02" />
        </div>
      </div>
    </div>
  );
}

export default MedicalSupport;
